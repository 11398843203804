/*videos */

.videos_scroll {
  overflow-y: auto;
  overflow-x: hidden;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.videos_scroll::-webkit-scrollbar {
  display: none;
}

.border-left-top-videos {
  border-left: 3px solid #1f1f1f;
}

@media screen and (max-width: 600px) {
  .border-left-top-videos {
    border-left: none;
  }
}

/* Hide scrollbar for IE, Edge and Firefox */


:root {
  --fontFmaily: Inter;
}

.demo {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.dialog-Paper-Deal {
  background-color: #121212 !important;
  border-radius: 20px !important;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.5) !important;
  border: solid 1px #1f1f1f !important;
}

.framemirror {
  text-align: center;
}

.videos_stream_container {
  display: flex;
  padding: 20px 10px 10px 10px !important;
}

.currentkey_direction {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 600px) {
  .hidden_comments_icons_in_mobile {
    display: none !important;
  }
}

.video_mobile_title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 15px;
}

.display_mobile_playcount {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

@media screen and (min-width: 601px) {
  .hidden_comments_icons_in_Desktop {
    display: none !important;
  }
}

@media screen and (max-width: 400px) {
  .MuiGrid-item .margin-top-mobiles-icons {
    margin-top: -50px;
  }
}

@media screen and (min-width: 400px) and (max-width: 450px) {
  .MuiGrid-item .margin-top-mobiles-icons {
    margin-top: -35px;
  }
}


.video_title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 15px;
}

.video_height_with_audio {
  height: 56vh;
}

@media screen and (min-height: 650px) {
  .video_height_with_audio {
    height: 60vh;
  }
}

@media screen and (min-height: 750px) and (max-height: 800px) {
  .video_height_with_audio {
    height: 64vh;
  }
}

@media screen and (min-height: 800px) {
  .video_height_with_audio {
    height: 65vh;
  }
}

@media screen and (min-height: 1000px) {
  .video_height_with_audio {
    height: 70vh;
  }
}

.video_username {
  display: flex;
  align-items: center;
}

.video_username_font {
  font-family: var(--fontFmaily);
  font-size: 12px;
  font-weight: bold;
  color: rgba(255, 255, 255, 0.5);
}

.display_playcount {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.videoplayer_background {
  position: relative;
  width: 100%;
  border-radius: 15px;
}

.videos_features_icons {
  position: absolute;
  top: 30%;
  left: 91%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  visibility: hidden;
}

.not_videos_features_icons {
  display: none;
}

.videos_icon_margin {
  margin: 10px;
  cursor: pointer;
}

.addComment_msg {
  outline: 0px;
  border-width: 0px 0px 5px 0px;
  border-color: #171717;
  background-color: black;
  padding: 15px 0px;
  width: 100%;
  border-radius: 5px;
  border: none;
}

.add_Comment_Input,
.add_Comment_Input:focus,
.add_Comment_Input::placeholder {
  color: #8f92a1;
  font-family: var();
  font-size: 15px;
  font-weight: bold;
  word-break: break-all;
  word-wrap: break-word;
  border: none;
  display: block;
  width: 80%;
  overflow: hidden;
  height: 50px;
  margin: 0px 10px;
}

.disable_resize {
  resize: none
}

.comment_input_border {
  border: 3px solid #171717;
  border-radius: 5px;
}

.comment_avatar {
  height: 42px !important;
  width: 42px !important;
  cursor: pointer;
}

.comment_avatar_default {
  height: 42px !important;
  width: 42px !important;
  cursor: pointer;
  object-fit: cover !important;
  border-radius: 50% !important;
  border: solid 2px #212121 !important;
}

.comment_button {
  border-radius: 25px;
  background-color: #ff0010;
  height: 25px;
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
  font-family: var(--fontFmaily);
  font-size: 12px;
  font-weight: bold;
  color: #ffffff;
  width: 79px;
  cursor: pointer;
  border: none;
  display: block;
}

#profilepic_in_statsTab {
  width: 48px !important;
  height: 48px !important;
  border-radius: 50px;
}

.currentKeyFont {
  font-family: var(--fontFmaily);
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
}

.comment_replay_main {
  background-color: #000000 !important;
}

.expandIconUp {
  width: 10%;
}

.replayComment {
  display: flex;
  font-family: var(--fontFmaily) !important;
}

.replyContainer {
  width: 80%;
  display: flex;
  align-items: baseline;
  justify-content: space-between;

}

@media screen and (min-width: 1240px) {
  .replyContainer{
    width: 100%;
  }
}

.repliesFont {
  font-family: var(--fontFmaily);
  font-size: 10px;
  font-weight: bold;
  color: #8f92a1;
}

.replayFont {
  font-family: var(--fontFmaily);
  font-size: 10px;
  font-weight: bold;
  color: #3959f9;
  padding-left: 20px;
  cursor: pointer;
}

.blockFont {
  font-family: var(--fontFmaily);
  font-size: 10px;
  font-weight: bold;
  color: grey;
  padding-left: 20px;
  cursor: pointer;
}

.deleteFont {
  font-family: var(--fontFmaily);
  font-size: 10px;
  font-weight: bold;
  color: red;
  padding-left: 20px;
  cursor: pointer;
}

.chart_tab_button {
  font-family: var(--fontFmaily);
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  height: 30px;
  border-radius: 30px;
  border: none;
}

.topbannerWrapper {
  padding: 15px 0px;
  justify-content: center;
}


.filterPosition {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding-right: 30px;
}

.video-comment-split-active {
  background-color: #3959f9 !important;
}

.video-comment-split-inactive {
  background-color: #212121 !important;
}

.videos_TabsWarpper {
  background-color: transparent;
  align-items: center;
}

.TabContainer {
  display: flex;
}

.this_year_graph {

  width: 350px;
  height: 40px;
  text-align: center;
  background-color: #000000;
  color: #ffffff;
  font-family: var(--fontFmaily);
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 20px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #202020;
}

@media screen and (max-width: 500px) {
  .this_year_graph {
    width: 240px;
    height: 40px;
    font-size: 11px;
    top: -18px;
  }
}

.elapsedTimeFont {
  font-family: var(--fontFmaily);
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
}

.videosList_content {
  padding: 25px !important;
}

.video_parent_div {
  border: 3px solid #1f1f1f !important;
  border-radius: 20px !important;
  background-color: #000 !important;
  margin-top: 10px !important;
  overflow-y: auto;
  overflow-x: hidden;
}

.videosList_child_content {
  padding: 10px 0px 10px 20px !important;
  border-radius: 20px !important;
  background-color: #000 !important;
  overflow-y: auto;
  border: 5px solid #141414;
}

.videoSonglist_height {
  height: 126vh;
  overflow-x: hidden;
  overflow-y: auto;
}



@media screen and (min-width: 575px) and (max-width: 700px) {
  .topvideos_margin {
    margin-top: 30px;
  }
}

@media screen and (min-width: 701px) and (max-width: 800px) {
  .topvideos_margin {
    margin-top: 90px;
  }
}

@media screen and (min-width: 801px) and (max-width: 959px) {
  .topvideos_margin {
    margin-top: 175px;
  }
}

@media screen and (min-width: 960px) and (max-width: 1059px) {
  .topvideos_margin {
    margin-top: 85px;
  }
}

@media screen and (min-width: 1060px) and (max-width: 1159px) {
  .topvideos_margin {
    margin-top: 140px;
  }
}

@media screen and (min-width: 1160px) and (max-width: 1259px) {
  .topvideos_margin {
    margin-top: 200px;
  }
}

@media screen and (min-width: 1260px) and (max-width: 1279px) {
  .topvideos_margin {
    margin-top: 220px;
  }
}



.topvideos_section_height {
  height: 100%;
}

.with_audio_topvideos_section_height {
  height: 100%;
}

@media screen and (min-width: 1400px) and (max-width: 1500px) {
  .topvideos_section_height {
    height: 105%;
  }
}

@media screen and (min-width: 1501px) and (max-width: 1600px) {
  .topvideos_section_height {
    height: 110%;
  }
}

@media screen and (min-width: 1601px) and (max-width: 1700px) {
  .topvideos_section_height {
    height: 115%;
  }
}

@media screen and (min-width: 1701px) {
  .topvideos_section_height {
    height: calc(100% + 10%);
  }
}


.control_icon {
  width: 30px !important;
  height: 30px !important;
}

.no_splits_padding {
  padding: 0px 30px;
}

@media screen and (max-width: 400px) {
  .no_splits_padding {
    padding: 0px 0px;
  }
}

.splitlist_height {
  height: 170px;
  overflow-x: hidden;
  overflow-y: auto;
}

.splitSection {
  margin: 15px 0px 0px;
  padding: 16px;
  border-radius: 15px;
  background-color: rgba(216, 216, 216, 0.03);
  background-color: transparent;
  height: 530px;
  border: 2px solid #202020;
}

.tabsection_container {
  padding: 10px 12px 20px 12px;
  border-radius: 15px;
  background-color: #040404;
  margin-top: 12px;
  width: -webkit-fill-available;
  height: 650px;
}

.comment_send_button {
  display: none;
}

.comment_send_display {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 1800px) {
  .videoSonglist_height {
    height: 162vh;
  }
}

@media screen and (max-width: 1750px) {
  .videoSonglist_height {
    height: 160vh;
  }
}

@media screen and (max-width: 1600px) {
  .videoSonglist_height {
    height: 158vh;
  }
}

@media screen and (max-width: 1550px) {
  .videoSonglist_height {
    height: 155vh;
  }
}

@media screen and (max-width: 1500px) {
  .videos_features_icons {
    top: 10%;
  }
}

@media screen and (max-width: 1500px) {
  .videoSonglist_height {
    height: 150vh;
  }
}

@media screen and (max-width: 1400px) {
  .videoSonglist_height {
    height: 145vh;
  }
}

@media screen and (max-width: 1400px) {

  .add_Comment_Input,
  .add_Comment_Input:focus {
    width: 75%;
  }
}

@media screen and (max-width: 1350px) {
  .videoSonglist_height {
    height: 145vh;
  }
}

@media screen and (max-width: 1300px) {
  .videoSonglist_height {
    height: 145vh;
  }
}

@media screen and (max-width: 1266px) {
  .videos_features_icons {
    top: 20%;
  }
}

@media screen and (max-width: 984px) {
  .videos_features_icons {
    top: 5%;
  }
}

@media screen and (max-width: 920px) {
  .videos_features_icons {
    top: 20%;
  }
}

@media screen and (max-width: 750px) {
  .videos_features_icons {
    top: 10%;
  }
}

@media screen and (max-width: 700px) {
  .videos_features_icons {
    flex-direction: row;
    left: 0px;
    top: 2px;
    width: 100%;
    display: flex;
  }
}


@media screen and (max-width: 600px) {
  .currentkey_direction {
    margin: 0px !important;
  }

  .TabContainer {
    flex-direction: column;
    align-items: center;
  }

  .videos_TabsWarpper button {
    margin: 10px 0px !important;
  }

  .video_title {
    justify-content: center;
    align-items: center;
  }

  .filterPosition {
    padding-right: 10px;
  }

  .splitSection {
    height: 550px;
  }

  .tabsection_container {
    height: 700px;
  }

  .add_Comment_Input,
  .add_Comment_Input:focus {
    width: 70%;
  }
}

@media screen and (max-width: 520px) {
  .tabAlign_section {
    width: 100%;
  }

  .comment_avatar {
    height: 30px !important;
    width: 30px !important;
    cursor: pointer;
  }

  .comment_button {
    display: none;
  }

  .comment_send_button {
    display: block;
    width: 50px;
  }
}

@media screen and (max-width: 400px) {
  .this_year_graph {
    width: 220px;
    height: 40px;
    font-size: 10px;
  }
}

/************************ old *****************************/
.wrapper {
  position: relative;
  left: 50%;
  width: 100%;
  height: 300px;
  transform: translate(-50%, 0);
  overflow: auto;
}

.message-container {
  position: relative;
  top: 18%;
  left: 39%;
  height: 100%;
  background-color: transparent;
  transform: translate(-40%, -48%);
  max-height: 38%;
  padding-left: 17px;
}

.you {
  float: left;
  color: white;
  background-color: #1a1b1f;
  align-self: flex-start;
  border-radius: 8px;
  border-top-left-radius: 0px;
  border: solid 1px #262626;
}

.me {
  float: right;
  color: white;
  background-color: #3959f9;
  align-self: flex-end;
  border-radius: 8px;
  border-top-right-radius: 0px;
}

.play-comment {
  display: flex;
  justify-content: flex-end;
}

.videos-repost {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.msgChats {
  display: flex;
  justify-content: space-between;
  text-align: left;
  width: 80%;
  color: white;
  border-radius: 8px;
  padding: 10px 7px;
  line-height: 1.5;
  font-size: 14px;
}

.you {
  background-color: #1a1b1f;
  border-top-left-radius: 0px;
}

.me {
  background-color: #3959f9;
  border-top-right-radius: 0px;
  margin-right: 5px;
}

.buttons {
  display: flex;
  margin-top: 20px;
}

.bubble {
  font-size: 16px;
  margin-bottom: 10px;
  color: #ffffff;
  padding: 1%;
}


.multicolor-bar .values .value {
  float: left;
  text-align: center;
}

.multicolor-bar .scale .graduation {
  float: left;
  text-align: center;
}

.multicolor-bar .bars .bar {
  float: left;
  height: 4px;
}

.multicolor-bar .bars div.bar:first-of-type {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.multicolor-bar .bars div.bar:last-of-type {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.multicolor-bar .legends {
  text-align: center;
}

.multicolor-bar .legends .legend {
  display: inline-block;
  margin: 0 5px;
  text-align: center;
}

.multicolor-bar .legends .legend .dot {
  font-size: 25px;
  vertical-align: middle;
}

.multicolor-bar .legends .legend .label {
  margin-left: 2px;
  vertical-align: middle;
}

.Multicolor-artist {
  font-size: 12px !important;
  color: #efefef !important;
  font-weight: 500 !important;
}

#songName_in_statsTab {
  margin-bottom: 2.5px !important;
}

#songDesc_in_statsTab {
  font-size: 14px !important;
}

#simple-tabpanel-2 {
  width: 100%;
}

.sendIcon_in_addComment {
  filter: grayscale(95%);
}

.add-comment,
.add-comment::placeholder {
  font-size: 14.5px;
}

.viewReplyIcon_in_commentsection {
  filter: invert(100%) sepia(100%) saturate(3207%) hue-rotate(210deg) brightness(95%) contrast(400%);
}

@media (min-width: 701px) {
  .splitsDataWrapper_in_statsTab>.multicolor-bar>.values>.value>div>.display> :nth-child(2) {
    margin-left: 12.5px !important;
  }

  .splitsDataWrapper_in_statsTab>.multicolor-bar>.values> :nth-child(1)>div>.display> :nth-child(2) {
    margin-left: 0px !important;
  }
}

@media (min-width: 2400px) {
  .splitsDataWrapper_in_statsTab>.multicolor-bar>.values>.value>div>.display> :nth-child(2) {
    margin-left: -25px !important;
  }

  .splitsDataWrapper_in_statsTab>.multicolor-bar>.values> :nth-child(1)>div>.display> :nth-child(2) {
    margin-left: -50px !important;
  }
}

@media (min-width: 701px) {
  .splitsDataWrapper_in_statsTab>.multicolor-bar>.values>.value>div>.display> :nth-child(2) {
    text-align: left;
  }
}

/**/

@media (max-width: 1600px) {
  .splitsDataWrapper_in_statsTab>.multicolor-bar>.values> :nth-child(2)>div>.display> :nth-child(3) {
    margin-right: 8px;
  }
}

@media (max-width: 600px) {
  .play-comment {
    display: flex;
    justify-content: center;
  }

  .videos-repost {
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
}

@media (max-width: 1055px) {
  .wrapper {
    width: 600px !important;
  }

  .message-container {
    width: 550px !important;
  }
}

@media (max-width: 725px) {
  .wrapper {
    width: 500px !important;
  }

  .message-container {
    width: 450px !important;
    padding-left: 0px;
  }
}

@media (max-width: 701px) {
  .splitsDataWrapper_in_statsTab>.multicolor-bar {
    display: grid !important;
    grid-template-columns: 60% 10% 30% !important;
  }

  .splitsDataWrapper_in_statsTab>.multicolor-bar>div {
    display: flex !important;
    flex-direction: column !important;
  }

  .splitsDataWrapper_in_statsTab>.multicolor-bar {
    justify-content: space-around !important;
  }

  .splitsDataWrapper_in_statsTab>.multicolor-bar>.values>.value>div>.display> :nth-child(1) {
    margin-left: 5px !important;
  }

  .splitsDataWrapper_in_statsTab>.multicolor-bar>.values>.value>div>.display> :nth-child(2) {
    margin-right: 75px !important;
    margin-left: 10px !important;
  }

  .splitsDataWrapper_in_statsTab>.multicolor-bar>.values>.value>div>.display> :nth-child(2)>div>p {
    width: 125px !important;
  }

  .bars {
    justify-content: space-around !important;
    margin-top: -15px;
  }
}

@media (max-width: 650px) {
  .splitsDataWrapper_in_statsTab>.multicolor-bar>.values>.value>div>.display> :nth-child(2)>div>p {
    width: 75px !important;
  }

  .splitsDataWrapper_in_statsTab>.multicolor-bar>.values>.value>div>.display> :nth-child(2) {
    margin-right: 50px !important;
    margin-left: 25px !important;
  }

  .splitsDataWrapper_in_statsTab>.multicolor-bar>.bars {
    margin-left: -20px !important;
  }

  .bubble {
    font-size: 12px !important
  }

  .wrapper {
    width: 450px !important;
  }

  .message-container {
    width: 400px !important;
    padding-left: 0px;
  }
}

@media (max-width: 580px) {
  .wrapper {
    width: 400px !important;
  }

  .message-container {
    width: 350px !important;
    padding-left: 0px;
  }
}

@media (max-width: 550px) {
  .splitsDataWrapper_in_statsTab>.multicolor-bar>.bars {
    margin-left: 0px !important;
  }
}

@media (max-width: 525px) {
  .wrapper {
    width: 350px !important;
  }

  .message-container {
    width: 300px !important;
    padding-left: 0px;
  }
}

.trendingBTn_in_statsTab-1 {
  margin-top: 5px !important;
  font-size: 13px !important;
  padding: 15px 0px !important;
  border-radius: 13px !important;
  background-color: #22252a !important;
  font-weight: 600 !important;
  text-transform: capitalize !important;
  height: 30px !important;
  width: -webkit-fill-available;
  margin-right: 6px !important;
  color: #8f9092 !important;
}

.trendingBTn_in_statsTab {
  margin-top: 5px !important;
  font-size: 13px !important;
}

.video_player_height {
  height: 406px !important;
}

@media (max-width: 501px) {
  .splitsDataWrapper_in_statsTab>.multicolor-bar>.values>.value>div>.display> :nth-child(2)>div>.song-desc {
    margin-left: 0px !important;
  }

  .splitsDataWrapper_in_statsTab>.multicolor-bar>.bars {
    margin-left: 50px !important;
  }

  .splitsDataWrapper_in_statsTab>.multicolor-bar>.values>.value>div>.display> :nth-child(2) {
    margin-right: 65px !important;
  }

  .videoPlayerWrapper_in_Comments {
    height: 200px !important;
  }

  .video_player_height {
    height: 200px !important;
  }

  #profilepic_in_statsTab {
    width: 48px !important;
    height: 48px !important;
  }

  #songName_in_statsTab {
    font-size: 14px !important;
  }

  #songDesc_in_statsTab {
    font-size: 12px !important;
  }

  .trendingIcon_in_statsTab {
    height: 16px !important;
    width: 16px !important;
  }

  @media (max-width: 768px) {
    .trendingBTn_in_statsTab {
      margin-top: 5px !important;
      font-size: 11px !important;
    }

    .buttons {
      display: contents;
    }

    .trendingBTn_in_statsTab-1 {
      margin-top: 5px !important;
      font-size: 11px !important;
      padding: 15px 20px;
    }
  }

  .statsTab_actionBtnImg {
    height: 22px !important;
    width: 22px !important;
  }

  .statsTab_actionBtnImg_comment {
    height: 18px !important;
    width: 18px !important;
    margin-top: 3px;
  }

  .songnameOutermostGrid_in_StatsTab {
    width: 120px !important;
    margin-left: -48px !important;
  }

  .song-desc {
    margin-left: -13px !important;
  }

  .icon_in_statsTab {
    margin-left: -5px !important;
  }

  .statsTab_actionButtonsWrapper {
    margin-left: 10px !important;
  }

  #tabs_in_statsTab {
    min-width: 70px !important;
    min-height: 25px !important;
    font-size: 9.5px !important;
    font-weight: 400 !important;
    margin-right: 4px !important;
  }

  .filterIconWrapper_in_statsTab {
    margin-top: -6px !important;
  }
}

@media (max-width: 480px) {
  .bubble {
    font-size: 10px !important;
  }

  .wrapper {
    width: 300px !important;
  }

  .message-container {
    width: 250px !important;
    padding-left: 0px;
  }

  .add-comment,
  .add-comment::placeholder {
    font-size: 10px;
  }
}

@media (max-width: 451px) {
  .songnameOutermostGrid_in_StatsTab {
    margin-left: -32px !important;
  }

  .sendIcon_in_addComment {
    width: 23px !important;
    height: 23px !important;
  }

}

@media (max-width: 430px) {
  .bubble {
    font-size: 10px !important;
  }

  .wrapper {
    width: 250px !important;
  }

  .message-container {
    width: 225px !important;
  }
}

@media (max-width: 400px) {
  .send-message-img {
    width: 35px !important;
    height: 35px !important;
  }

  .add-comment {
    margin-left: 36px;
  }

  .add-comment,
  .add-comment::placeholder {
    padding-left: 0px !important;
  }
}

@media (max-width: 426px) {
  .songnameOutermostGrid_in_StatsTab {
    margin-left: -16px !important;
  }

  .statsTab_actionButtonsOuterWrapper {
    margin-right: -10px !important;
  }

  .tabsWarpper_in_statsTab>div>div> :nth-child(1) {
    margin-left: 10px !important;
  }

  .filterIconWrapper_in_statsTab {
    right: 12px !important;
    margin-top: -7px !important;
  }
}

@media (max-width: 376px) {
  .videoPlayerWrapper_in_Comments {
    height: 175px !important;
  }

  .video_player_height {
    height: 175px !important;
  }
}

@media (max-width: 416px) {
  #tabs_in_statsTab {
    min-width: 60px !important;
    font-size: 10px !important;
  }

  .topbannerWrapper_in_statsTab {
    max-height: 200px !important;
  }

  .songnameOutermostGrid_in_StatsTab {
    margin-left: 5px !important;
  }

  .trendingAndButtonsWrapper_in_statsTab {
    display: flex !important;
  }

  .statsTab_actionButtonsOuterWrapper {
    margin-left: 35px !important;
  }

  .trendingIcon_in_statsTab {
    margin-right: 4px !important;
    margin-top: 2px !important;
  }
}

@media (max-width: 401px) {
  .splitsDataWrapper_in_statsTab>.multicolor-bar>.values>.value>div>.display> :nth-child(2)>div>p {
    font-size: 10px !important;
  }

  .splitsDataWrapper_in_statsTab>.multicolor-bar>.values>.value>div>.display> :nth-child(3)>div>span {
    font-size: 14px !important;
  }

  .splitsDataWrapper_in_statsTab>.multicolor-bar>.bars {
    display: none !important;
  }
}

@media (max-width: 400px) {
  .tabsWarpper_in_statsTab>div>div> :nth-child(1) {
    margin-left: 4px !important;
  }

  .filterIconWrapper_in_statsTab {
    right: 4px !important;
  }
}

@media (max-width: 383px) {
  #tabs_in_statsTab {
    min-width: 60px !important;
    font-size: 9px !important;
    margin-right: 0 !important;
  }

  .trendingIcon_in_statsTab {
    margin-right: 2px !important;
  }

  .statsTab_actionButtonsOuterWrapper {
    margin-left: 38px !important;
  }

  .songnameOutermostGrid_in_StatsTab {
    margin-left: 20px !important;
  }
}

@media (max-width: 380px) {
  .bubble {
    font-size: 10px !important;
  }

  .wrapper {
    width: 250px !important;
    margin-left: -8px;
  }

  .message-container {
    width: 230px !important;
  }
}

@media (max-width: 376px) {
  .filterIconWrapper_in_statsTab>img {
    width: 12px !important;
    height: 10px !important;
  }
}

@media (max-width: 363px) {
  #tabs_in_statsTab {
    min-width: 50px !important;
    font-size: 9px !important;
  }

  .tabsWarpper_in_statsTab>div>div> :nth-child(1) {
    margin-left: 5px !important;
  }

  .songnameOutermostGrid_in_StatsTab {
    margin-left: 30px !important;
  }

  .statsTab_actionButtonsOuterWrapper {
    margin-left: 42px !important;
  }

  .bubble {
    font-size: 9px !important;
  }

  .wrapper {
    margin-left: -12px;
  }
}

@media (max-width: 356px) {
  #tabs_in_statsTab {
    margin-right: -1px !important;
    padding-left: 8px;
    padding-right: 8px;
  }

  .filterIconWrapper_in_statsTab {
    right: 6px !important;
  }
}

@media (max-width: 351px) {
  .songnameOutermostGrid_in_StatsTab {
    margin-left: 40px !important;
  }

  .trendingIcon_in_statsTab {
    margin-right: 0px !important;
  }

  .statsTab_actionButtonsOuterWrapper {
    margin-left: 43px !important;
  }
}

@media (max-width: 345px) {
  .tabsWarpper_in_statsTab>div>div> :nth-child(1) {
    margin-left: 4px !important;
  }

  .me {
    max-width: 90px;
  }

  .wrapper {
    width: 225px !important;
    margin-left: -15px;
  }

  .message-container {
    width: 180px !important;
  }

  #sendIcon__MsgSectionComponent {
    width: 20px !important;
    height: 20px !important;
  }
}

@media (max-width: 340px) {
  .filterIconWrapper_in_statsTab {
    right: 5px !important;
    top: 20px !important;
  }

  .tabsWarpper_in_statsTab>div>div> :nth-child(1) {
    margin-left: 6px !important;
  }
}

@media (max-width: 335px) {
  .songnameOutermostGrid_in_StatsTab {
    margin-left: 42px !important;
  }

  .statsTab_actionButtonsOuterWrapper {
    margin-left: 46px !important;
  }

  .filterIconWrapper_in_statsTab {
    top: 20px !important;
    right: 2px !important;
  }

  .tabsWarpper_in_statsTab>div>div> :nth-child(1) {
    margin-left: 3px !important;
  }
}

@media (max-width: 335px) {
  .filterIconWrapper_in_statsTab {
    top: 24px !important;
  }
}

@media (max-width: 330px) {
  .wrapper {
    width: 225px !important;
    margin-left: -20px;
  }
}

.left-margin-ant .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
  margin-left: -10px;
}

#select_playlist {
  margin-left: -10px;
}